import React, { useEffect } from "react"
import LayoutComponent from "../components/LayoutComponent/LayoutComponent"
import MenuComponent from "../components/MenuComponent/MenuComponent"
import "../styles/global.scss"
import SEO from "../components/seo"
import ImageGallery from "react-image-gallery"
import "react-image-gallery/styles/css/image-gallery.css"
import { unloadRecaptcha } from "../utils/Captcha"
import VideoComponent from "../components/VideoComponent/VideoComponent"

const Archiv = () => {
  useEffect(() => {
    unloadRecaptcha()
  }, [])

  const images = [
    {
      original: "/archiv/Gruppenbild_2015.jpg",
      thumbnail: "/archiv/Gruppenbild_2015.jpg",
    },
    {
      original: "/archiv/Gruppenbild_2014.jpg",
      thumbnail: "/archiv/Gruppenbild_2014.jpg",
    },
    {
      original: "/archiv/Gruppenbild_2013.jpg",
      thumbnail: "/archiv/Gruppenbild_2013.jpg",
    },
    {
      original: "/archiv/Gruppenbild_2012.jpg",
      thumbnail: "/archiv/Gruppenbild_2012.jpg",
    },
    {
      original: "/archiv/Gruppenbild_2012_JHV.jpg",
      thumbnail: "/archiv/Gruppenbild_2012_JHV.jpg",
    },
    {
      original: "/archiv/Gruppenbild_2010_Sommer.jpg",
      thumbnail: "/archiv/Gruppenbild_2010_Sommer.jpg",
    },
    {
      original: "/archiv/Gruppenbild_2010_Bout.jpg",
      thumbnail: "/archiv/Gruppenbild_2010_Bout.jpg",
    },
    {
      original: "/archiv/Gruppenbild_2009_USCar.jpg",
      thumbnail: "/archiv/Gruppenbild_2009_USCar.jpg",
    },
    {
      original: "/archiv/Gruppenbild_2009_Bout.jpg",
      thumbnail: "/archiv/Gruppenbild_2009_Bout.jpg",
    },
    {
      original: "/archiv/Gruppenbild_2009_Bout_Erster.jpg",
      thumbnail: "/archiv/Gruppenbild_2009_Bout_Erster.jpg",
    },
    {
      original: "/archiv/Gruppenbild_2008.jpg",
      thumbnail: "/archiv/Gruppenbild_2008.jpg",
    },
    {
      original: "/archiv/17906-roller-skates-pv.jpg",
      thumbnail: "/archiv/17906-roller-skates-pv.jpg",
    },
    {
      original: "/archiv/Gifs/FOOTWORK_converted.gif",
      thumbnail: "/archiv/Gifs/FOOTWORK_converted.gif",
    },
    {
      original: "/archiv/Gifs/FOOTWORK2_converted.gif",
      thumbnail: "/archiv/Gifs/FOOTWORK2_converted.gif",
    },
    {
      original: "/archiv/Gifs/FUNPUSH_converted.gif",
      thumbnail: "/archiv/Gifs/FUNPUSH_converted.gif",
    },
    {
      original: "/archiv/Gifs/liegestuetz_converted.gif",
      thumbnail: "/archiv/Gifs/liegestuetz_converted.gif",
    },
    {
      original: "/archiv/Gifs/RENNEN_converted.gif",
      thumbnail: "/archiv/Gifs/RENNEN_converted.gif",
    },
  ]

  return (
    <LayoutComponent languageSwitchSubpage="archiv">
      <SEO title="Archiv" />
      <MenuComponent selected={0} />
      <div style={{ marginTop: "50px" }}>
        <ImageGallery thumbnailPosition={"left"} items={images} />
      </div>

      <p style={{ textAlign: "center" }}>
        <a href="#">Youtube Playlist</a>
      </p>

      <VideoComponent title={"sportlehrehrung_2020.mp4"} />
      <VideoComponent title={"sommerfest.mp4"} />
      <VideoComponent title={"tshirt.mp4"} />
      <div className="archive-article">
        <h2>Erfolg 2020</h2>
        <p>
          "Die Barockcity Spieler Carl Taylor (aka Bloodred Kelly) und Sören
          Schneemann (aka Brad Hit) werden für ihre Berufung in die
          Nationalmannschaft im letzten Jahr geehrt. Mit dem deutschen Männer
          Nationalteam konnten sie im Februar 2020 überlegen den Nations‘ Cup in
          Frankfurt gewinnen. Am Turnier nahmen neben Deutschland die Teams aus
          Italien und Dänemark teil, gespielt wurde im Modus jeder gegen jeden
          mit Hin- und Rückspiel. Unsere Jungs trugen zu den souveränen Siegen
          gegen beide Teams bei und konnten so mit Deutschland den Turniersieg
          feiern. Auch für die WM waren die beiden nominiert, diese wurde aber
          aufgrund der Corona Pandemie abgesagt.
          <br />
          <br />
          Corona-bedingt kann die Sportlerehrung dieses Jahr nicht in Präsenz
          stattfinden, deshalb hat der MTV Ludwigsburg ein Video gedreht mit
          allen Sportlern des MTV, die für ihre Erfolge im Jahr 2020
          ausgezeichnet wurden:
          <a href="https://www.youtube.com/watch?v=mswOqfntVys">
            https://www.youtube.com/watch?v=mswOqfntVys"
          </a>
        </p>
        <div className="img-container">
          <img src="/archiv/sportlerehrung_cut.png" alt="Sportlerehrung" />
        </div>
      </div>
      <div className="archive-article">
        <h2>Brezel Bash 2019</h2>
        <p>
          Im Rahmen der bayrischen Meisterschaften 2019 in Regensburg gab es ein
          mixed game, an dem wir teilgenommen haben. Wir haben uns riesig
          gefreut, zu viert beim „Brezel Bash“ dabei zu sein! Unsere Trinity hat
          ihr Rollerderbydebut gegeben, unser Desperados Chainsaw, Funny
          Commander und Mighty Mimi konnten Spielerfahrung sammeln. Auch wenn es
          hieß ziemlich früh aufstehen- wir hatten Riesen Spaß!"
        </p>
        <div className="img-container">
          <img src="/archiv/brezelbash.png" alt="Brezelbash" />
        </div>
      </div>
    </LayoutComponent>
  )
}

export default Archiv
